import { Box } from '@chakra-ui/react';

export default function ReviewsHero() {
  return (
    <Box
      display={'inline-block'}
      mt={'6vh'}
      textAlign={'center'}
      flexDirection={'column'}
      mb={'3rem'}
      letterSpacing={'0.1rem'}
    >
      <h1>
        Reviews
      </h1>
    </Box>
  );
}
