import { Box, Text, Image } from '@chakra-ui/react';
import photo from '../../assets/images/fishing-lake-inland.webp';
import Provided from '../provided/ProvidedCharter';
import year from '../../variables/year';
import charterInlandFullPrice from '../../variables/charter';

export default function LakeInland() {
  return (
    <Box textAlign={'center'} pb={'3rem'} px={'5%'}>
        I offer a morning Lake Superior charter, The Donut Run, where you'll fish for
        <br />
        <span className={'textEnhanced'}>lake trout and salmon</span>
        <br /><br />
        Then I take a break and go on an afternoon inland trip where you'll fish for
        <br />
        <span className={'textEnhanced'}>smallmouth bass, walleyes, northern pike and muskie</span>
        <br /><br />
        
        <Text fontSize={'2xl'} fontWeight={'bold'}>Full Day Package Rate for {year}</Text>
        <span className={'textEnhanced'} style={{fontSize: 35}}>${charterInlandFullPrice}</span>
        <br />
        for up to 4 people.
        <br /><br />
        Because of the size of the boat, 
        <br /><span className={'textEnhanced'}>trips are limited to four people</span>
        <br /><br />
        <Image alt="Lake Superior Charter Fishing" src={photo} maxW={'full'} height={'auto'} width={'auto'} className={'imgFeature'} dropShadow={'0 0.5rem 1rem #000'} />
        <Provided />
    </Box>
  );
}
