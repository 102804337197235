import {
  Box,
  Stack,
  Text,
  Wrap,
  WrapItem,
  Container,
  Link
} from '@chakra-ui/react';
import { InfoOutlineIcon, ChatIcon, CalendarIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used
import { ReactNode } from 'react';
import { PhoneLinkInline } from '../components/PhoneLink';
import { EmailLinkInline } from '../components/EmailLink';

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text as="h2" fontWeight={'500'} fontSize={'lg'} mb={2} className={'footerHeading'}>
      {children}
    </Text>
  );
};

export default function Footer() {
  return (
    <Container
      display={'flex'}
      m={'0'}
      p={'0.5rem'}
      mt={'0.5rem'}
      w={'full'}
      minW={'full'}
      justifyContent={'center'}
      justifyItems={'center'}
      bg={'rgba(108, 140, 133, 0.1)'}
      >
      <Box
      display={'flex'}
      maxW={'100rem'}
      px={'1rem'}
      flexDirection={'column'}
      position={'relative'}
      bg={'#313131'}
      borderRadius={'1rem'}
      border={'2px solid #98acbf'}
      borderStyle={'outset'}
      boxShadow={'dark-lg'}
      opacity={'0.9'}
      
    >
      <Wrap flexWrap={'wrap'} justify={'center'} mt={'2rem'} justifySelf={'center'}>
        <WrapItem>
        <Box minW={'15rem'} mb={'2rem'}>
          <ListHeader>
            <InfoOutlineIcon mb={'1rem'} />
            <Text variant={'footerHeading'} textAlign={'center'}>Serving</Text>
          </ListHeader>
          <Stack direction={'column'} align={'center'} spacing={2}>
            <Link href="https://www.google.com/maps/place/Grand+Marais,+MN+55604" target={'_blank'}><Text>Grand Marais, MN</Text></Link>
            <Text>And The Surrounding Area</Text>
          </Stack>
        </Box>
        </WrapItem>
        <WrapItem>
        <Box minW={'15rem'} mb={'2rem'}>
          <ListHeader>
            <CalendarIcon mb={'1rem'} />
            <Text variant={'footerHeading'}>Hours</Text>
          </ListHeader>
          <Stack direction={'column'} align={'center'} spacing={2}>
            <Text>Available 24 / 7</Text>
          </Stack>
          </Box>
        </WrapItem>
        <WrapItem>
        <Box minW={'15rem'} mb={'2rem'}>
          <ListHeader>
            <ChatIcon mb={'1rem'} />
            <Text variant={'footerHeading'}>Contact Us</Text>
          </ListHeader>
          <Stack direction={'column'} align={'center'} spacing={2}>
            <PhoneLinkInline />
            <EmailLinkInline />
          </Stack>
          </Box>
        </WrapItem>
      </Wrap>
      <Box className={'wrapperFacebook'} justifyContent={'center'} mb={'1rem'}>
        <a href="https://www.facebook.com/Northshoreoutdoors-313944661970460" title='Visit Our Facebook Page' className={'linkFacebook'} target='_blank' rel='noreferrer'>
          <span className={'iconTitle'}>
            <FontAwesomeIcon className={'iconFacebook'} icon={brands('square-facebook')} />
          </span>
          Visit Our Facebook Page
        </a>
    </Box>    
  </Box>
  </Container>
  );
}
