import { Box,
  Text,
  Image,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td } from '@chakra-ui/react';
import photo from '../../assets/images/fishing-charter.webp';
import Provided from '../provided/ProvidedCharter';

import year from '../../variables/year';
import {threeHour, fiveHour, eightHour} from '../../variables/charter';

export default function LakeSuperiorBody() {



  return (
    <Box textAlign={'center'} mb={'1rem'}>
      <Box maxWidth={'800px'}>
        My Lake Superior charters are based out of the Grand Marais harbor. 
        I am a life long resident and the only full time charter captain and fishing guide out of Grand Marais. 
        This is my only job, not a part time or weekend job. 
        <br /><br />
        This keeps me on the water everyday as long as the weather permits it. 
        I am a Coast Guard- licensed captain and have a 22-foot Bayliner Trophy hardtop
        for my Lake Superior charters.
        <br /><br />
        <Image alt="Lake Superior Charter Fishing" src={photo} maxW={'full'} height={'400px'} width={'auto'} className={'imgFeature'} dropShadow={'0 0.5rem 1rem #000'} />
        <Provided />
    <Box my={'1rem'} bg={'rgba(90, 90, 90, 0.5)'} p={'1rem'} borderRadius={'1rem'} borderStyle={'outset'}>
      <Text fontSize={'2xl'} fontWeight={'bold'}>Charter Times</Text>
      <br />
      <Text fontSize={'1.3rem'} fontWeight={'bold'}>
        Donut Run
      </Text>
      <span className={'textEnhanced'}>
        7:00am - 12:00pm
      </span>
      <br /><br />
      <Text fontSize={'1.3rem'} fontWeight={'bold'}>
        Summer Breeze
      </Text>
      <span className={'textEnhanced'}>
        2:00pm - 7:00pm
      </span>
    </Box>
<br />
<Box my={'1rem'} py={'2rem'} bg={'rgba(90, 90, 90, 0.5)'} borderRadius={'1rem'} borderStyle={'outset'}>

  <Text fontSize={'1.7rem'} fontWeight={'bold'} lineHeight={'2.5rem'} mb={'1rem'}>
    Lake Superior Charter Fishing
    <br />
    Rates for {year}
  </Text>
  <hr />
  <Text fontSize={'1.3rem'} fontWeight={'bold'} mt={'1rem'}>
    Two Hour Charters
  </Text>
  <Box mx={'5%'} mt={'1rem'}>
  <TableContainer>
  <Table width={'auto'} variant='striped' colorScheme={'blackAlpha'} bg={'rgba(90, 90, 90, 0.5)'} margin={'auto'}>
    <Thead>
      <Tr>
        <Th textAlign={'center'} color={'orange'}>Number of People</Th>
        <Th color={'orange'}>Rate</Th>
      </Tr>
    </Thead>
    <Tbody>
    {threeHour.map((item, index) => (
      <Tr key={index}>
        <Td textAlign={'center'}>{item[0]}</Td>
        <Td>${item[1]}</Td>
      </Tr>
    ))}
    </Tbody>
  </Table>
</TableContainer>
</Box>
<Box mx={'5%'} mt={'2rem'} border={'2px solid rgba(90, 90, 90, 0.7)'} p={'1rem'} borderRadius={'1rem'} bg={'rgba(90, 90, 90, 0.2)'}>
  
<Text fontSize={'1.3rem'} fontWeight={'bold'} mt={'0.5rem'}>Half Day 5 Hour Charters</Text>
<Text fontSize={'0.9rem'}>Veterans Receive a 10% Discount</Text>
  <TableContainer mt={'1rem'}>
  <Table width={'auto'} variant='striped' colorScheme={'blackAlpha'} bg={'rgba(90, 90, 90, 0.5)'} margin={'auto'}>
    <Thead>
      <Tr>
        <Th textAlign={'center'} color={'orange'}>Number of People</Th>
        <Th color={'orange'}>Rate</Th>
      </Tr>
    </Thead>
    <Tbody>
    {fiveHour.map((item, index) => (
      <Tr key={index}>
        <Td textAlign={'center'}>{item[0]}</Td>
        <Td>${item[1]}</Td>
      </Tr>
    ))}
    </Tbody>
  </Table>
</TableContainer>
</Box>
<Text fontSize={'1.3rem'} fontWeight={'bold'} mt={'2rem'}>Full Day 8 Hour Charters</Text>
<Box mx={'5%'} mt={'1rem'}>
  <TableContainer>
  <Table width={'auto'} variant='striped' colorScheme={'blackAlpha'} bg={'rgba(90, 90, 90, 0.5)'} margin={'auto'}>
    <Thead>
      <Tr>
        <Th textAlign={'center'} color={'orange'}>Number of People</Th>
        <Th color={'orange'}>Rate</Th>
      </Tr>
    </Thead>
    <Tbody>
    {eightHour.map((item, index) => (
      <Tr key={index}>
        <Td textAlign={'center'}>{item[0]}</Td>
        <Td>${item[1]}</Td>
      </Tr>
    ))}
    </Tbody>
  </Table>
  </TableContainer>
</Box>
</Box>
  </Box>
</Box>
  );
}
