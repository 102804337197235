import { Box, Link, Text } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';

export default function BookBox() {
    return (
      <Box 
      maxW={'40rem'}
      my={'1rem'}
      py={'1rem'}
      px={'1rem'}
      pb={'1.5rem'}
      bg={'rgba(40, 40, 40, 0.5)'}
      borderRadius={'1rem'}
      borderStyle={'solid'}
      borderWidth={'0.25rem'}
      borderColor={'rgba(150, 190, 250, 0.5)'}
      mx={'auto'}
      >
        <Text fontSize={'1.7rem'} fontWeight={'bold'} lineHeight={'2.5rem'} mb={'0.75rem'}>
          To Book a Trip:
        </Text>
        <Box mx={'5%'}>
          <Text textAlign={'center'} fontSize={'1.25rem'} lineHeight={'2rem'}>
            Call <Link href="tel:+12183707826" style={{color: 'skyblue'}} textDecoration={"none"}>(218) 370-8726</Link> or fill out
            our <Link as={ReactRouterLink} to={'/contact'} style={{color: 'skyblue'}} textDecoration={"none"}>convenient form</Link>
            <br />and we'll call you back.
          </Text>
        </Box>
      </Box>
    );
}
