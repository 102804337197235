import { EmailIcon } from '@chakra-ui/icons';
import { Box } from '@chakra-ui/react';

export default function EmailLink() {
    return (
        <h3>
            <EmailLinkInline />
        </h3>
    );
}

export function EmailLinkInline() {
    return (
        <Box>
            <EmailIcon mr={'1rem'} />
            <a href="mailto:kelly@northshoreoutdoorsmn.com" className={'emailLinkIcon'} color={'white'}>
                kelly@northshoreoutdoorsmn.com
            </a>
        </Box>
    );
}
