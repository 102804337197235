import { Box, Container } from '@chakra-ui/react';
import NavDesktop from './NavDesktop';
import NavMobile from './NavMobile';
export default function Navigation() {

  return (
    <Container width={'full'} minW={'full'}>
      <Box display={{ base: 'none', md: 'flex' }}>
        <NavDesktop />
      </Box>
      <Box display={{ base: 'flex', md: 'none' }}>
        <NavMobile />
      </Box>
    </Container>
  );
}



