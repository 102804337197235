import { Box, Image } from '@chakra-ui/react';
import inland from '../../assets/images/contact.jpg';
import ContactFormLayout from './Form';

export default function Contact() {
  return (
    <Box pb={'3rem'}>
      <ContactFormLayout />
      <Image alt="Contact Us" src={inland} w={'670px'} maxW={'full'} className={'imgFeature'} dropShadow={'0 0.5rem 1rem #000'} />
    </Box>
  );
}
