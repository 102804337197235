import { Box } from '@chakra-ui/react';
import PhoneLink from './PhoneLink';
import EmailLink from './EmailLink';
import Tagline from './Tagline';
interface HeroData {
  title: string;
}

export default function Hero({ title }: HeroData) {
  return (
    <Box
      display={'inline-block'}
      mt={'4rem'}
      textAlign={'center'}
      flexDirection={'column'}
      mb={'3rem'}
      letterSpacing={'0.1rem'}
    >
      <h1>
        {title}
      </h1>
      <Tagline />
      <PhoneLink />
      <EmailLink />
    </Box>
  );
}
