import { Box, Button, Link } from '@chakra-ui/react';
export default function NavDesktop() {

  return (
    <Box minW={'full'}>
      <center>
        <Box display={'inline-flex'} justifySelf={'center'} justifyContent={'center'} flexWrap={'wrap'} overflowWrap={'normal'}>
          <Link href='/'>
            <Button variant={'menu'}>Home</Button>
          </Link>
          <Link href='/#/lake-superior-charter-fishing'>
            <Button variant={'menu'}>Charter Fishing</Button>
          </Link>
          <Link href='/#/inland-fishing'>
            <Button variant={'menu'}>Inland Fishing</Button>
          </Link>
          <Link href='/#/lake-superior-inland-fishing'>
            <Button variant={'menu'}>
              Lake Superior & Inland Fishing</Button>
          </Link>
          {/*<Button variant={'menu'}>Call Back</Button>*/}
          <Link href='/#/gallery'>
            <Button variant={'menu'}>Gallery</Button>
          </Link>
          <Link href='/#/about'>
            <Button variant={'menu'}>About</Button>
          </Link>
        </Box>
      </center>
    </Box>
  );
}
