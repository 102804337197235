import { HashRouter, Route, Routes } from 'react-router-dom';

import App from '../layout/Main';
import Inland from '../pages/Inland';
import LakeAndInland from '../pages/LakeAndInland';
import LakeSuperior from '../pages/LakeSuperior';
import Gallery from '../pages/Gallery';
import Reviews from '../pages/Reviews';
import About from '../pages/About';
import Contact from '../pages/Contact';

export default function MainRoutes() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/inland-fishing" element={<Inland />} />
        <Route path="/lake-superior-inland-fishing" element={<LakeAndInland />} />
        <Route path="/lake-superior-charter-fishing" element={<LakeSuperior />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/" element={<App />} />
      </Routes>
    </HashRouter>
  );
}
