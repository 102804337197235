import { HamburgerIcon } from '@chakra-ui/icons';
import { 
  Portal,
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Divider,
  Link,
  Spacer
} from '@chakra-ui/react';
import { PhoneLinkInline } from './PhoneLink';

export default function NavMobile() {

  return (
<Box
  color='white'
  display={{ base: 'block', md: 'none' }}
  position={'absolute'}
  minH={'4rem'}
  minW={'full'}
  top={'1rem'}
  left={'1rem'}
  zIndex={2}
>
<Menu>
  <MenuButton
    as={Button}
    aria-label='Options'
    leftIcon={<HamburgerIcon />}
    px={'1rem'}
    mb={'1.8rem'}
    transition="all 0.2s"
    borderRadius="md"
    borderWidth="2px"
    bg={{ bg: 'green.500' }}
    _hover={{ bg: 'blue.700' }}
    _expanded={{ bg: 'blue.700' }}
    filter={'drop-shadow(0px 4px 3px rgba(80, 80, 80, 1))'}
    title={'Menu'}
    variant={'classic'}
  >
    Menu
  </MenuButton>
  <Portal>
    <MenuList>
      <MenuItem>
        <Link href='/'>
          Home
        </Link>
      </MenuItem>
      <MenuItem pl={'2rem'}>
        <Link href='/#/lake-superior-charter-fishing'>
          Charter Fishing
        </Link>
      </MenuItem>
      <MenuItem pl={'2rem'}>
        <Link href='/#/inland-fishing' variant={''}>
          Inland Fishing
        </Link>
      </MenuItem>
      <MenuItem pl={'2rem'}>
        <Link href='/#/lake-superior-inland-fishing'>
          Lake Superior & Inland Fishing
        </Link>
      </MenuItem>
      <Divider />
      <MenuItem>
        <Link href='/#/gallery'>
          Gallery
        </Link>
      </MenuItem>
      <MenuItem>
        <Link href='/#/about'>
          About
        </Link>
      </MenuItem>
      <Divider />
    </MenuList>
  </Portal>
</Menu>
<Box position={'relative'} display={'flex'} top={'5.2rem'} left={'-1rem'}>
    <Spacer />
      <Button
        as="button"
        variant={'classic'}
        filter={'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5))'}
      >
        <PhoneLinkInline />
      </Button>
    <Spacer />
    <Spacer />
    <Box>
      <Link href='/#/contact'>
        <Button
          as="button"
          variant={'classic'}
          filter={'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5))'}
        >
          Request a Call Back
        </Button>
      </Link>
    </Box>
    <Spacer />
  </Box>
</Box>
  );
}



