// @ts-nocheck
import { Image, Box, Container } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import image1 from '../assets/gallery/processed-14465252-612b-4705-a2b3-f54c60a56969_fFV7j9Zn.jpeg';
import image2 from '../assets/gallery/processed-1d6777f6-f978-434c-8276-9ff776d2a5e5_G3PezR1S.jpeg';
import image3 from '../assets/gallery/processed-1d7a1ec8-1208-4fcb-8a89-29b62730c1d4_rm3KxHQe.jpeg';
import image4 from '../assets/gallery/processed-1f896563-9f04-42b5-933b-6aecfe2b6ec6_7sWjgoCE.jpeg';
import image5 from '../assets/gallery/processed-2655c7ff-e27d-4de4-b831-9218f96a95eb_fdnti8Z7.jpeg';
import image6 from '../assets/gallery/processed-30b5988d-a6d6-41b6-b6b5-a66f27e8e9b7_rLv6QrZV.jpeg';
import image7 from '../assets/gallery/processed-500f892c-f66e-4384-b77b-c81456bac0cb_jBKN1r8y.jpeg';
import image8 from '../assets/gallery/processed-5ed8821b-2149-45d2-8d9c-5b8b894152d0_qnt67Sac.jpeg';
import image9 from '../assets/gallery/processed-6629e4c6-ccbb-482b-be67-015afec6034b_TeXvme17.jpeg';
import image10 from '../assets/gallery/processed-74a52209-b498-41d1-b2cf-31f01c5f5a94_2GICbUT9.jpeg';
import image11 from '../assets/gallery/processed-8715bc34-cca6-48fa-8d5f-627363064a49_1Okdek1b.jpeg';
import image12 from '../assets/gallery/processed-87cc023c-f0cc-46c7-9161-4edf32f3badc_JSMln7s2.jpeg';
import image13 from '../assets/gallery/processed-8811bcba-663a-4c2c-86e0-aed10489a619_anFiLMLF.jpeg';
import image14 from '../assets/gallery/processed-8dcc6020-9d18-4090-9d72-66656e23b791_cm45YQJF.jpeg';
import image15 from '../assets/gallery/processed-91392975-fc88-48b7-9e2e-b7b50507e48b_yK8kCJfc.jpeg';
import image16 from '../assets/gallery/processed-b3d730ab-ef14-469c-85e8-e921e93da177_r659wPZ7.jpeg';
import image17 from '../assets/gallery/processed-b99e6353-f83a-40ae-a3ae-8c779fa239f9_64WBPqzD.jpeg';
import image18 from '../assets/gallery/processed-bcea36bf-5cd9-4cab-9e7a-296a7aa48ee9_998FuXFN.jpeg';
import image19 from '../assets/gallery/processed-c17f3596-1b79-4f63-b519-d96e330abbb8_dGCuFSYO.jpeg';
import image20 from '../assets/gallery/processed-ce662e56-3951-48bb-8ff1-57a68b205a1c_coT6yvnl.jpeg';
import image21 from '../assets/gallery/processed-d7e2ec1d-ebe5-4bab-bbdd-8aed09281f5b_8qwGEjfF.jpeg';
import image22 from '../assets/gallery/processed-dc2c6428-e5f4-48b0-95df-47f5b7d89fe7_hVemh3Qr.jpeg';
import image23 from '../assets/gallery/processed-ee40611a-2ad2-462c-b7b0-1de98ab11216_emCPrKyA.jpeg';
import image25 from '../assets/gallery/processed-f64e7a2a-e37e-4488-ad8b-2f570b425950_EG6H5sd3.jpeg';
import image26 from '../assets/gallery/processed-f8079be1-09fc-43ba-8c41-726ecdfe413e_yUemh8p0.jpeg';
import image27 from '../assets/gallery/processed-fcbd58dd-8712-4c98-b8e1-a92c16b41c23_M5ZZNT8D.jpeg';

export default function Main() {

  const refToTop = useRef<HTMLInputElement>(null);

  useEffect(() => {
    refToTop.current && refToTop.current.scrollIntoView();
  })
  
  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
    image17,
    image18,
    image19,
    image20,
    image21,
    image22,
    image23,
    image25,
    image26,
    image27
  ];

  return (
    <Container
      display={'flex'}
      m={'0'}
      p={'0.5rem'}
      mt={'16rem'}
      w={'full'}
      minW={'full'}
      justifyContent={'center'}
      justifyItems={'center'}
      bg={'rgba(108, 140, 133, 0.1)'}
      >
      <Box
      display={'flex'}
      maxW={'100rem'}
      p={'1rem'}
      borderRadius={'1rem'}
      backdropFilter={'auto'}
      backdropBlur={'5px'}
      background={'rgba(55,75,95,0.4)'}
      flexDirection={'column'}
    >
      <Box
        borderRadius={'1rem'}
        padding={4}
        w="100%"
        maxW="900px"
        mx="auto"
        bg="gray.800"
        sx={{ columnCount: [1, 2, 3], columnGap: "8px" }}
      >
        {images.map((src) => (
          <Image
            key={src}
            w="100%"
            borderRadius="xl"
            mb={2}
            d="inline-block"
            src={src}
            alt="Alt"
          />
        ))}
      </Box>
    </Box>
    </Container>
  );
}
