export const inlandFullPrice = 900;
const inlandPrice = 375;
const inlandMulti = 75;

export const inlandCharter = [
  ['1-2', inlandPrice, '4'],
  ['3', (inlandPrice + inlandMulti), '4'],
  ['4', (inlandPrice + (inlandMulti * 2)), '4']
];


export default inlandFullPrice;