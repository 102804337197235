import { Box, Container } from '@chakra-ui/react';
import ReviewsHero from '../components/reviews/Hero';
import ReviewsBody from '../components/reviews/Body';

export default function Reviews() {
  return (
    <Container
      display={'flex'}
      m={'0'}
      p={'0.5rem'}
      mt={'15rem'}
      w={'full'}
      minW={'full'}
      justifyContent={'center'}
      justifyItems={'center'}
      bg={'rgba(108, 140, 133, 0.1)'}
      >
      <Box
      display={'flex'}
      maxW={'100rem'}
      px={'1rem'}
      borderRadius={'1rem'}
      backdropFilter={'auto'}
      backdropBlur={'5px'}
      background={'rgba(55,75,95,0.4)'}
      flexDirection={'column'}
    >
      <ReviewsHero />
      <ReviewsBody />
    </Box>
    </Container>
  );
}
