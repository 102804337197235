import { Button, Box, Text, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import photo from '../assets/images/fishing-lake-inland.webp';

export default function LakeAndInland() {
  return (
    <Box pt={'5rem'}>
        <Box 
        maxW={'40rem'}
        bg={'rgba(40, 40, 40, 0.2)'}
        pt={'0.3rem'}
        pb={'0.03rem'}
        borderRadius={'1rem'}
        borderStyle={'solid'}
        borderTop={'solid 0.25rem rgba(150, 190, 250, 0.5)'}
        borderColor={'rgba(150, 190, 250, 0.5)'}
        mx={'auto'}
        >
          <Text variant={'heading'}>Lake Superior & Inland Full Day Packages</Text>
        </Box>
      <Image alt="Lake Superior & Inland Fishing" src={photo} className={'imgFeature'} maxW={'full'} height={'auto'} width={'auto'} filter={'auto'} contrast="80%" brightness="113%" dropShadow={'0 0.5rem 1rem #000'}/>
      <Box 
        maxW={'40rem'}
        my={'1rem'}
        py={'2rem'}
        bg={'rgba(40, 40, 40, 0.5)'}
        borderRadius={'1rem'}
        borderStyle={'solid'}
        borderWidth={'0.25rem'}
        borderColor={'rgba(150, 190, 250, 0.5)'}
        mx={'auto'}
        >
        <Text>
        I offer a 
        <br />
        <span className={'textEnhanced'}>full day package</span>
        <br />
        <br />that includes both 
        <br />
        <span className={'textEnhanced'}>the morning Lake Superior charter</span>
        <br />
         and
         <br />
         <span className={'textEnhanced'}>the Afternoon inland trip</span>
      </Text>
      <Box mt={'2rem'} display={'flex'} justifyContent={'center'}>
        <Box w={'60%'}>
          <Link to={'/lake-superior-inland-fishing'}>
            <Button
              as="button"
              w={'100%'}
              bg={'blue.400'}
              color={'white'}
              _hover={{
                bg: 'blue.500'
              }}
            >
              Learn More
            </Button>
          </Link>
        </Box>
      </Box>
      </Box>
    </Box>
  );
}
