import {
  Box,
  ChakraProvider,
  Container,
  extendTheme
} from '@chakra-ui/react';
import ChakraTheme from '@chakra-ui/theme';
import customTheme from './theme/Custom';
import './App.css';
import Footer from './layout/Footer';
import Header from './layout/Header';
import background from './assets/bg-lake-shore.webp';
import '@fontsource/raleway/600.css';
import '@fontsource/open-sans/700.css';
import '@fontsource/rubik/400.css';
import MainRoutes from './routes/Main';

const breakpoints = {
  sm: '30em',
  //md: '70.35em',
  md: '46em',
  lg: '82em',
  xl: '90em',
  '2xl': '106em'
}

const theme = extendTheme(ChakraTheme, customTheme, { breakpoints });
function App() {
  return (
    
    <ChakraProvider theme={theme}>
      <Container minW={'full'} minH={'full'} m={0} p={0}>
      <Box
        backgroundImage={`url(${background})`}
        backgroundSize="cover"
        backgroundPosition="middle"
        backgroundAttachment={'scroll'}
        backgroundColor={'rgba(108, 140, 178, 1)'}
        minW={'full'}
        minH={'100%'}
        h={'100vh'}
        m={0}
        p={0}
        zIndex="-1"
        overflow="scroll"
      >
        <Box m={0} p={0} w={'full'} alignItems={'center'}>
          <Header />
          <MainRoutes />
          <Footer />
        </Box>
      </Box>
      </Container>
    </ChakraProvider>
  );
}

export default App;
