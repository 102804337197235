import {Box, FormLabel, Textarea} from '@chakra-ui/react';

const TextareaField = (props) => {
  const { handleChange, label, name, value } = props;
  return (
    <Box>
      <FormLabel className="textEnhanced" fontWeight={'bold'} fontSize={'xl'} mb={'0.1rem'} mt={'1.5rem'} htmlFor={name}>{label}: </FormLabel>
      <Textarea onChange={handleChange} id={name} name={name} rows="4" value={value}></Textarea>
    </Box>
  )
}

export default TextareaField
