import { useEffect, useRef } from 'react';
import { Box, Container } from '@chakra-ui/react';
import Hero from '../components/Hero';
import Body from '../components/inland/Body';

export default function Inland() {

const refToTop = useRef<HTMLInputElement>(null);

useEffect(() => {
  refToTop.current && refToTop.current.scrollIntoView();
})

  return (
    <>
    <Box h={'15rem'} ref={refToTop}>
    </Box>
    <Container
      display={'flex'}
      m={'0'}
      p={'0.5rem'}
      w={'full'}
      minW={'full'}
      justifyContent={'center'}
      justifyItems={'center'}
      bg={'rgba(108, 140, 133, 0.1)'}
      >
      <Box
      display={'flex'}
      maxW={'100rem'}
      px={'1rem'}
      borderRadius={'1rem'}
      backdropFilter={'auto'}
      backdropBlur={'5px'}
      background={'rgba(55,75,95,0.4)'}
      flexDirection={'column'}
    >
      <Hero title="Inland Lake Fishing" />
      <Body />
    </Box>
    </Container>
    </>
  );
}
