import { extendBaseTheme } from '@chakra-ui/react';
import { baseTheme } from '@chakra-ui/theme';
import { StyleFunctionProps } from '@chakra-ui/theme-tools';
import { menuTheme } from './Menu';

const customTheme = extendBaseTheme({
  fonts: {},
  colors: {
    ...baseTheme.colors,
    brand: baseTheme.colors.blue
  },
  styles: {
    global: (props: StyleFunctionProps) => ({
      html: {
        color: 'white'
      },
      body: {
        color: 'white',
        bg: '#363b43',
        height: '100%'
      },
      h1: {
        color: 'white',
        fontSize: '3xl',
        fontWeight: 'bold',
        letterSpacing: '0.2rem',
        lineHeight: '1.4',
        pb: '1rem'
      },
      h2: {
        fontSize: '24px',
        color: '#e0b743',
        pb: '0.7rem'
      },
      h3: {
        fontSize: '16px',
        color: 'orange',
        pb: '0.7rem'
      }
    })
  },
  components: {
    ...baseTheme.components,
    Text: {
      baseStyle: {
        color: 'gray.200',
        fontSize: '16px',
        fontWeight: 'normal',
        lineHeight: '24px'
      },
      variants: {
        footerHeading: {
          color: 'white',
          fontSize: '2rem',
          fontWeight: 'bold',
          lineHeight: '1.4',
          textTransform: 'uppercase'
        },
        footerText: {
          color: 'white',
          fontSize: '16px',
          fontWeight: 'normal',
          textAlign: 'center'
        },
        heading: {
          fontSize: '2rem',
          fontWeight: 'bold',
          lineHeight: '1.4',
          textAlign: 'center',
          mb: '1rem'
        }
      },
      defaultProps: {
        variant: 'footerText'
      }
    },
    Box: {
      baseStyle: {
        backdropFilter: 'blur(25px)',
        backgroundColor: 'rgba(255, 255, 255, 0.5)'
      }
    },
    Stack: {
      baseStyle: {
        color: 'yellow',
        fontSize: '16px',
        fontWeight: 'normal',
        lineHeight: '24px',
        padding: '0px'
      }
    },
    Menu: menuTheme,
    Button: {
      defaultProps: {
        background: 'blue'
      },
      baseStyle: {
        fontWeight: 'bold',
        borderRadius: '0.5rem'
      },
      variants: {
        classic: {
          backgroundColor: '#1b2379',
          color: 'white',
          _hover: {
            backgroundColor: '#84aee1'
          }
        },
        menu: {
          color: '#fcfeff',
          fontSize: '1.0rem',
          letterSpacing: '0.15rem',
          mx: '0.2rem',
          _hover: {
            backgroundColor: 'rgba(27, 35, 121, 0.8)',
            color: '#fff'
          }
        },
        standard: {
          backgroundColor: 'blue.400',
          color: 'white',
          _hover: {
            bgColor: 'blue.500'
          }
        },
        outline: {
          border: '1px solid',
          borderColor: 'blue.400',
          color: 'blue.400',
          _hover: {
            bg: 'blue.400',
            color: 'blue.200'
          }
        }
      }
    }
  }
});

export default customTheme;
