export const charterInlandFullPrice = 950;
const threePrice = 250;
const threeMulti = 25;

export const threeHour = [
  ['1-2', threePrice],
  ['3', (threePrice + threeMulti)],
  ['4', (threePrice + (threeMulti * 2))],
  ['5', (threePrice + (threeMulti * 3))],
  ['6', (threePrice + (threeMulti * 4))]
];

const fivePrice = 400;
const fiveMulti = 50;
export const fiveHour = [
  ['1-2', fivePrice],
  ['3', (fivePrice + fiveMulti)],
  ['4', (fivePrice + (fiveMulti * 2))],
  ['5', (fivePrice + (fiveMulti * 3))],
  ['6', (fivePrice + (fiveMulti * 4))]
];

const eightPrice = 900;
const eightMulti = 100;
export const eightHour = [
  ['1-2', eightPrice],
  ['3', (eightPrice + eightMulti)],
  ['4', (eightPrice + (eightMulti * 2))],
  ['5', (eightPrice + (eightMulti * 3))],
  ['6', (eightPrice + (eightMulti * 4))]
];

export default charterInlandFullPrice;