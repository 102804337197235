import { PhoneIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Container,
  Image,
  Link,
  Spacer
} from '@chakra-ui/react';
import logo from '../assets/logo.webp';
import Navigation from '../components/Navigation';
export default function Header() {
  return (
    <Container 
      position={'fixed'}
      top={0}
      left={0}
      w={'full'}
      minW={'full'}
      bg={'rgba(108, 140, 178, 1)'}
      boxShadow={'dark-lg'}
      zIndex={1}
    >
      <Box position={'relative'} height={'2rem'}>
        <Box display={{ base: 'none', md: 'flex' }} position={'relative'} top={'2.5rem'}>
          <Spacer />
          <Link href='tel:+1-218-370-8726'>
            <Button
              as="button"
              variant={'classic'}
            >
              <PhoneIcon mr={'1rem'} />
              Book Now
            </Button>
          </Link>
          <Spacer />
          <Spacer />
          <Box>
            <Link href='/#/contact'>
            <Button
              as="button"
              variant={'classic'}
            >
              Request a Call Back
            </Button>
            </Link>
          </Box>
          <Spacer />
        </Box>
      </Box>
      <Box my={'auto'} display={'flex'} justifyContent={'center'}>
        <Image src={logo} w={'128px'} mb={'1rem'} h={'138px'} zIndex={2} alt={'North Shore Outdoors MN Logo'} />
      </Box>
      <Navigation />
    </Container>
  );
}
