import { PhoneIcon } from '@chakra-ui/icons';
import { Box } from '@chakra-ui/react';

export default function PhoneLink() {
    return (
        <h2>
            <PhoneLinkInline />
        </h2>
    );
}

export function PhoneLinkInline() {
    return (
        <Box className={'phoneLinkIcon'}>
            <PhoneIcon mr={'1rem'} />
            <a href="tel:+1-218-370-8726" className={'phoneLinkIcon'}>
                (218) 370 - 8726
            </a>
        </Box>
    );
}
