import { Text } from '@chakra-ui/react';
export default function ReviewsBody() {
  return (
    <Text bg={'rgba(90, 90, 90, 0.5)'} fontSize={'1.25rem'} lineHeight={'2.25rem'}
      p={'2rem'} pt={'1rem'} borderStyle={'outset'} borderRadius={'1rem'}
      filter={'box-shadow: 0 0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.5);'}
      >
Reviews Go Here
    </Text>
  );
}
