import { Button, Box, Text, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import inland from '../assets/images/fishing-inland.webp';

export default function Inland() {
  return (
    <Box bg={'rgba(90, 90, 90, 0.5)'} 
      borderStyle={'outset'}
      borderRadius={'1rem'}
      textAlign={'center'}
      p={'1.5rem'}
      pb={'2rem'}
    >
        <Box
        maxW={'40rem'}
        bg={'rgba(40, 40, 40, 0.2)'}
        pb={'0.03rem'}
        borderRadius={'1rem'}
        borderStyle={'solid'}
        borderTop={'solid 0.25rem rgba(150, 190, 250, 0.5)'}
        borderColor={'rgba(150, 190, 250, 0.5)'}
        mx={'auto'}
        >
      <Text mt={'1rem'} variant={'heading'}>
        Inland Fishing
      </Text>
      </Box>
      <Image 
        alt="Inland Fishing"
        src={inland}
        maxW={'full'}
        className={'imgFeature'}
        dropShadow={'0 0.5rem 1rem #000'} 
        />
      <Text my={'2rem'}>
        Inland fishing trips are offered on lakes located up the
        <br/>
        <span className={'textEnhanced'}>
          Sawbill, Caribou, Gunflint, and Arrowhead trails
        </span><br />
        inside and outside of the Boundary Waters Canoe Area Wilderness ( BWCA )
        <br /><br />
        You'll fish for
        <br />
        <span className={'textEnhanced'}>
          Smallmouth Bass, Walleyes, Northern Pike and Muskie
        </span>
        <br />
      </Text>
      <Box mt={'2rem'} display={'flex'} justifyContent={'center'}>
        <Box w={'65%'}>
          <Link to={'/inland-fishing'}>
            <Button
              width={'100%'}
              as="button"
              bg={'blue.400'}
              color={'white'}
              _hover={{
                bg: 'blue.500'
              }}
            >
              Learn More
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
