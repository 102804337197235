import { Box, Input, InputGroup, InputLeftAddon } from '@chakra-ui/react';

const InputField = (props) => {
  const { handleChange, label, name, type, value } = props;
  return (
    <div className='imgFeature textEnhanced'>
      <Box w={'100%'}>
        <InputGroup variant="custom">
          <InputLeftAddon htmlFor={name} fontSize={'0.85rem'} bg={'skyblue'} color={'gray.700'}>{label}:</InputLeftAddon>
          <Input color={'gray.700'} type={type} onChange={handleChange} value={value} name={name} />
        </InputGroup>
      </Box>
    </div>
  )
}

export default InputField
