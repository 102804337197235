import { Box, Text, Image, TableContainer, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import inland from '../../assets/images/fishing-inland.webp';
import Provided from '../provided/ProvidedInland';

import year from '../../variables/year';
import {inlandFullPrice, inlandCharter} from '../../variables/inland';

export default function InlandBody() {

  return (
    <Box pb={'3rem'} textAlign={'center'} px={'5%'} pt={'0'} mt={'0'}>
        I offer inland fishing trips for lakes located up the
        <br/>
        <span className={'textEnhanced'}>
          Sawbill, Caribou, Gunflint, and Arrowhead trails
        </span><br />
        inside and outside of the Boundry Water Canoe Area Wilderness
        <br /><br />
        You'll fish for
        <br />
        <span className={'textEnhanced'}>
          Smallmouth Bass, Walleyes, Northern Pike and Muskie
        </span>
        <br /><br />
        <Image alt="Inland Fishing" src={inland} maxW={'full'} className={'imgFeature'} dropShadow={'0 0.5rem 1rem #000'} />
        <br />
        <Provided />
      <br />
      Because of the size of the boat,
      <br /><span className={'textEnhanced'}>trips are limited to four people</span>
      <br /><br />
      <Box my={'1rem'} py={'2rem'} bg={'rgba(90, 90, 90, 0.5)'} borderRadius={'1rem'} borderStyle={'outset'}>
        <Text fontSize={'1.7rem'} fontWeight={'bold'} lineHeight={'2.5rem'} mb={'1rem'}>
          Inland Fishing
          Rates for {year}
        </Text>
        <Box mx={'5%'} mt={'1rem'}>
        <TableContainer>
        <Table width={'auto'} variant='striped' colorScheme={'blackAlpha'} bg={'rgba(90, 90, 90, 0.5)'} margin={'auto'}>
          <Thead>
            <Tr>
              <Th maxWidth={'5%'} textAlign={'center'} color={'orange'}>Hours</Th>
              <Th textAlign={'center'} color={'orange'}>People</Th>
              <Th color={'orange'} textAlign={'center'}>Rate</Th>
            </Tr>
          </Thead>
          <Tbody>
          {inlandCharter.map((item, index) => (
            <Tr key={index}>
              <Td textAlign={'center'}>{item[2]}</Td>
              <Td textAlign={'center'}>{item[0]}</Td>
              <Td>${item[1]}</Td>
            </Tr>
          ))}
          <Tr>
            <Td textAlign={'center'}>8</Td><Td textAlign={'center'}>Up to 4</Td><Td> ${inlandFullPrice}</Td>
          </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      </Box>
      </Box>
    </Box>
  );
}
