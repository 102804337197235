import React from 'react'
import { Box, FormLabel, Select } from '@chakra-ui/react';
//      <FormLabel className="textEnhanced" fontWeight={'bold'} fontSize={'xl'} mb={'0.1rem'} mt={'1.5rem'} htmlFor={name}>{label}: </FormLabel>

const SelectField = (props) => {
  const { label, handleChange, name } = props;
  return (
    <Box pt={'1.3rem'}>
      <FormLabel className="textEnhanced" fontWeight={'bold'} fontSize={'xl'} mb={'0.1rem'} htmlFor={name}>{label}: </FormLabel>
      <Select style={{ color: 'black' }} onChange={handleChange} bg={'skyblue'} defaultValue="-- Select One --" name={name}>
        <option style={{ color: 'gray.700' }} value="-- Select One --" disabled>-- Select One --</option>
        <option style={{ color: 'black' }} value="Lake Superior Charter">Lake Superior Charter</option>
        <option style={{ color: 'black' }} value="Inland Fishing" className="py-1">Inland Fishing</option>
        <option style={{ color: 'black' }} value="Lake Superior & Inland Fishing Package" className="py-1">Lake Superior & Inland Fishing Package</option>
        <option style={{ color: 'black' }} value="Other" className="py-1">Other</option>
      </Select>
    </Box>
  )
}

export default SelectField
