import { Box } from '@chakra-ui/react';
import ContactForm from './ContactForm';

export default function ContactFormLayout() {
  return (
      <Box px={'5%'}>
        <ContactForm />
      </Box>
  );
}
