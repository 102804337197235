import { Flex, Box, VStack } from '@chakra-ui/react';
import About from './MainAbout';
import Inland from './MainInland';
import LakeSuperior from './MainLakeSuperior';
import LakeAndInland from './MainLakeAndInland';

export default function Home() {
  return (
      <Box pb={'2rem'}>
        <Flex width={'full'}>
          <VStack h={'10%'} w={'100%'}>
            <About />
            <LakeSuperior />
            <Inland />
            <LakeAndInland />
          </VStack>
        </Flex>
      </Box>
  );
}
