import { Button, Box, Text, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import photo from '../assets/images/fishing-charter.webp';

export default function LakeSuperior() {
  return (
    <Box pb={'4rem'} pt={'2rem'}>
      <Box 
      maxW={'40rem'}
      bg={'rgba(40, 40, 40, 0.2)'}
      pt={'0.3rem'}
      pb={'0.03em'}
      borderRadius={'1rem'}
      borderStyle={'solid'}
      borderTop={'solid 0.25rem rgba(150, 190, 250, 0.5)'}
      borderColor={'rgba(150, 190, 250, 0.5)'}
      mx={'auto'}
      >
        <Text variant={'heading'} mb={'0.5rem'}>Lake Superior Charters</Text>
      </Box>
    <Image alt="Charter" src={photo} maxW={'full'} height={'400px'} className={'imgFeature'} />
    <Box 
        maxW={'40rem'}
        my={'1rem'}
        py={'2rem'}
        px={'1rem'}
        bg={'rgba(40, 40, 40, 0.5)'}
        borderRadius={'1rem'}
        borderStyle={'solid'}
        borderWidth={'0.25rem'}
        borderColor={'rgba(150, 190, 250, 0.5)'}
        mx={'auto'}
        >
        <Text mb={'1rem'}>
          Choose from the
          <br />
          <span className={'textEnhanced'}>Donut Run</span>
          <br />
          ( 7:00 am - 12:00 pm )
          <br /> or <br />
          <span className={'textEnhanced'}>Summer Breeze</span>
          <br />
          ( 2:00 pm - 7:00 pm )
        </Text>
      <Text mb={'2rem'}>
      You'll fish for
      <br />
      <span className={'textEnhanced'}>
      {' '}Lake Trout and King, Coho, and Pink Salmon
      </span>
      <br />
        on a 22-foot Bayliner Trophy hardtop.
      <br /><br />
      10% discount for Veterans on half-day Lake Superior charters
    </Text>
    <Box mt={'1.2rem'} display={'flex'} justifyContent={'center'}>
      <Box w={'60%'}>
      <Link to={'/lake-superior-charter-fishing'}>
        <Button
          as="button"
          w={'100%'}
          bg={'blue.400'}
          color={'white'}
          _hover={{
            bg: 'blue.500'
          }}
        >
          Learn More
        </Button>
      </Link>
      </Box>
      </Box>
    </Box>
  </Box>
  );
}
