import { useEffect, useState } from "react"
import InputField from "./InputField"
import InputFieldRequired from './InputFieldRequired';
import SelectField from "./SelectField";
import TextareaField from "./TextareaField";
import emailjs from "@emailjs/browser";
import { Text, Box, Button, Heading } from '@chakra-ui/react';

const ContactForm = () => {
  const [values, setValues] = useState({
    from_name: '',
    reply_to: '',
    phone: '',
    reason: '',
    date: '',
    payment: '',
    message: ''
  });
  const [status, setStatus] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Configure the email service parameters
    const serviceId = 'service_9jk4zkk'; // Replace with your EmailJS service ID
    const templateId = 'template_2u8h2bd'; // Replace with your EmailJS template ID
    const userId = 'zo8Ew1W0eNnY-ZEk8'; // Replace with your EmailJS user ID
    // Send the email
    emailjs.send(serviceId, templateId, values, userId)
      .then(response => {
        console.log('SUCCESS!', response);
        setValues({
          from_name: '',
          reply_to: '',
          phone: '',
          reason: '',
          date: '',
          payment: '',
          message: ''
        });
        setStatus('SUCCESS');
      }, error => {
        console.log('FAILED...', error);
      });
  }

  useEffect(() => {
    if(status === 'SUCCESS') {
      setTimeout(() => {
        setStatus('');
      }, 3000);
    }
  }, [status]);

  const handleChange = (e) => {
    setValues(values => ({
      ...values,
      [e.target.name]: e.target.value
    }))
  }
  return (
    <Box my={'1rem'} p={'2rem'} bg={'rgba(90, 90, 90, 0.3)'} borderRadius={'1rem'} borderStyle={'outset'}>
      <Heading as={'h1'} fontSize={'1.65rem'} textAlign={'center'} mb={'1rem'}>
        Request a Call Back
      </Heading>
      {status && renderAlert()}
      <form onSubmit={handleSubmit}>
        <InputFieldRequired value={values.fullName} handleChange={handleChange} label="Name" name="from_name" type="text" placeholder="Your Name" />
        <InputField value={values.email} handleChange={handleChange} label="E-Mail" name="reply_to" type="email" placeholder="your@gmail.com" />
        <InputField value={values.email} handleChange={handleChange} label="Phone" name="phone" type="phone" placeholder="555-555-5555" />
        <InputField value={values.date} handleChange={handleChange} label="Date" name="date" type="date" dateFormat="dd/MM/yyyy" placeholder="" />
        <SelectField handleChange={handleChange} name="reason" label="Package" />
        <TextareaField value={values.message} handleChange={handleChange} label="Details" name="message" />
        <Button type="submit"
                    variant={'classic'}
                    filter={'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5))'}
                    mt={'2rem'}
                    alignContent={'center'}
                    width={'100%'}
        > Send Your Message
        </Button>
      </form>
    </Box>
  )
}

const renderAlert = () => (
  <div className="px-4 py-3 leading-normal text-blue-700 bg-blue-100 rounded mb-5 text-center">
    <Box>
      <Text className="textEnhanced">
        <span className='textEnhanced'>your message submitted successfully</span>
      </Text>
    </Box>
  </div>
)

export default ContactForm
