import { Box } from '@chakra-ui/react';
import LicenseStamp from './LicenseStamp';

export default function Provided() {
  return (
    <Box textAlign={'center'}>
      I provide
      <br /><span className={'textEnhanced'}>
        All safety and fishing equipment
      </span><br />
      I also clean and package your fish at the end of the trip
      <br /><br />
      All you need to bring is
      <br />
      <LicenseStamp />
      <br />
      your camera, and something to munch on.
      <br />
    </Box>
  );
}
