import { Box, Container } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import AboutBody from '../components/about/Body';
import Hero from '../components/Hero';

export default function About() {
  const refToTop = useRef<HTMLInputElement>(null);

  useEffect(() => {
    refToTop.current && refToTop.current.scrollIntoView();
  })
  
  return (
    <>
    <Box h={'15rem'} ref={refToTop}>
    </Box>
    <Container
      display={'flex'}
      m={'0'}
      p={'0.5rem'}
      w={'full'}
      minW={'full'}
      justifyContent={'center'}
      justifyItems={'center'}
      bg={'rgba(108, 140, 133, 0.1)'}
      >
      <Box
      display={'flex'}
      maxW={'100rem'}
      px={'1rem'}
      borderRadius={'1rem'}
      backdropFilter={'auto'}
      backdropBlur={'5px'}
      background={'rgba(55,75,95,0.4)'}
      flexDirection={'column'}
    >
      <Hero title="Kelly Shepard" />
      <AboutBody />
    </Box>
    </Container>
    </>
  );
}
