import { Box, Text } from '@chakra-ui/react';
import BookBox from './BookBox';
export default function About() {
  return (
    <Box bg={'rgba(60, 60, 60, 75%)'} alignSelf={'center'} textAlign={'center'} ml={'auto'} mr={'auto'} w={'95%'}
      mx={'0'} px={'0'}
      p={'1rem'} pt={'1rem'} mb={'3rem'} borderStyle={'outset'} borderRadius={'1rem'}
      filter={'box-shadow: 0 0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.5);'}
      >
      <Box 
      bg={'rgba(40, 40, 40, 0.4)'}
      pt={'0.3rem'}
      pb={'0.03em'}
      borderRadius={'1rem'}
      borderStyle={'solid'}
      borderTop={'solid 0.25rem rgba(150, 190, 250, 0.5)'}
      borderColor={'rgba(150, 190, 250, 0.5)'}
      mx={'auto'}
      px={'auto'}
      mb={'1.5rem'}
      >
        <Text variant={'heading'} fontSize={'1.25rem'} mb={'0.5rem'}>About The Captain</Text>
      </Box>
      <Text lineHeight={'1.3rem'} textAlign={'center'}>
        Captain Kelly Shepard is a life long 5th-generation guide to Grand Marais,
        located on the scenic North Shore of Minnesota.
        <br /><br />
        He has been guiding fishermen since the mid-1970s.
        <br /><br />
        His vast knowledge and experience of Lake Superior and 
        inland lakes will help your outdoor adventure be a
        memorable and successful one.
        <br /><br /> 
        You'll arrive as a customer and leave as a friend.
        <br /><br />
      </Text>
      <BookBox />
    </Box>
  );
}
