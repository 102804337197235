import { Text, Box, Image } from '@chakra-ui/react';
import photo from '../../assets/images/bg-rods.jpg';
export default function AboutBody() {
  return (
    <Box bg={'rgba(90, 90, 90, 0.5)'} fontSize={'1.25rem'} lineHeight={'2.25rem'}
      p={'2rem'} pt={'1rem'} borderStyle={'outset'} borderRadius={'1rem'}
      filter={'box-shadow: 0 0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.5);'}
      mb={'5rem'} textAlign={'center'}
      >
      <Box 
      maxW={'40rem'}
      bg={'rgba(40, 40, 40, 0.2)'}
      pt={'0.3rem'}
      pb={'0.1rem'}
      borderRadius={'1rem'}
      borderStyle={'solid'}
      borderTop={'solid 0.25rem rgba(150, 190, 250, 0.5)'}
      borderColor={'rgba(150, 190, 250, 0.5)'}
      mx={'auto'}
      mt={'1rem'}
      mb={'2.5rem'}
      >
        <Text fontSize={'1.5rem'} fontWeight={'bold'} pb={'0.5rem'}>
          More Than 45 Years of Experience
        </Text>
      </Box>
      Experience an exciting fishing expedition led by a professional guide of the North Shore with over 45 years of experience.
      <br /><br />
      <Image alt="About Us" src={photo} maxW={'full'} height={'auto'} width={'auto'} className={'imgFeature'} dropShadow={'0 0.5rem 1rem #000'} />
      <br />
      Established in 1980, we are a family and locally owned and operated company offering Lake Superior charter fishing for lake trout, king salmon, coho salmon and pink salmon.
      <br /><br />
      As well as Inland fishing excursions for smallmouth bass, walleye, northern pike and muskie.
      <br /><br />
      We provide all the safety and fishing equipment and clean and package your fish when we are done. 
      <br /><br />
    </Box>
  );
}
